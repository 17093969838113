import React from "react";
import LoginForm from "./widgets/LoginForm";

import "./login.styles.scss";

export default function LoginView() {
	return (
		<div className="login__view">
			<LoginForm />
		</div>
	);
}
