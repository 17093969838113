import React from "react";

function EndSessionMessageMCQView() {
	return (
		<div className="session__waiting">
			<div className="message">
				<div className="detail">
					<h3>Your MCQ session has ended.</h3>
					<h2>Your subjective session starts soon.</h2>
				</div>
			</div>
		</div>
	);
}

export default EndSessionMessageMCQView;
