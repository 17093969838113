import React from "react";
import logo from "assets/logo.png";

import { Button, Layout, Popconfirm } from "antd";

import "./appheader.styles.scss";
import { useAuth } from "store/entities/auth/useAuth";

function AppHeader() {
	const { logout } = useAuth();

	const _logout = () => {
		logout();
	};

	return (
		<Layout.Header className="appheader">
			<div className="logo">
				<img src={logo} alt="somtu logo" />
			</div>
			<div className="logout">
				<Popconfirm
					placement="bottomLeft"
					title="Are you sure? You cannot log in again."
					okText="Yes, log out."
					onConfirm={_logout}
				>
					<Button type="link">Log out</Button>
				</Popconfirm>
			</div>
		</Layout.Header>
	);
}

export default AppHeader;
