import React from "react";

function WaitingMCQView() {
	return (
		<div className="session__waiting">
			<div className="message">
				<div className="detail">
					<h3>Your session has not started yet. Please wait.</h3>
					<small>
						You will be redirected to your session as soon as it
						start.
					</small>
				</div>
			</div>
		</div>
	);
}

export default WaitingMCQView;
