import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

import { ITimer } from "store/entities/timer/timer.model";
import { useTimer } from "store/entities/timer/useTimer";
import { differenceInMilliseconds } from "date-fns";

const _threshold = 2; // 2 minutes
interface LiveWatchProps {
	onExpiration?: () => void;
}

const _uploadThreshold = 15;
const _graceThreshold = 15;

function LiveWatchLongQuestion({ onExpiration }: LiveWatchProps) {
	// const [timeDiff, setTimeDiff] = useState<number>(0);
	const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
	const [requesting, setRequesting] = useState<boolean>(false);

	const timer = useTimer();

	useEffect(() => {
		setRequesting(true);
		timer
			.getTimer()
			.then((timers) => {
				const _timers: ITimer = {
					mcqEndTime: new Date(timers.mcqEndTime),
					mcqStartTime: new Date(timers.mcqStartTime),
					lngqEndTime: new Date(timers.lngqEndTime),
					lngqStartTime: new Date(timers.lngqStartTime),
					currentTime: new Date(timers.currentTime),
				};

				let endTime = _timers.lngqEndTime;

				let difInMilSeconds = Math.abs(
					differenceInMilliseconds(
						new Date(_timers.currentTime),
						new Date(endTime)
					)
				);
				const shifted = difInMilSeconds + Date.now();

				setSecondsRemaining(shifted);
			})
			.catch(() => {})
			.finally(() => {
				setRequesting(false);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="watch">
			{!requesting ? (
				<Countdown
					date={secondsRemaining}
					onComplete={() => {
						onExpiration && onExpiration();
					}}
					renderer={({ hours, minutes: _mins, seconds }) => {
						const minutes = _mins + hours * 60;

						return (
							<>
								<small>
									{minutes >=
									_uploadThreshold + _graceThreshold
										? "Time (remaining)"
										: minutes >= _graceThreshold
										? "Upload Time (remaining)"
										: "Session Over"}
								</small>
								<h2
									className={`clock ${
										hours === 0 && minutes <= _threshold
											? " red"
											: ""
									}`}
								>
									{(minutes >=
									_uploadThreshold + _graceThreshold
										? minutes -
										  (_uploadThreshold + _graceThreshold)
										: minutes >= _graceThreshold
										? minutes - _graceThreshold
										: 0
									)
										.toString()
										.padStart(2, "0")}
									:
									{(minutes > _graceThreshold ? seconds : 0)
										.toString()
										.padStart(2, "0")}
								</h2>
							</>
						);
					}}
				/>
			) : null}
		</div>
	);
}

export default LiveWatchLongQuestion;
