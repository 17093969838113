import { Button, Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import React from "react";
import { useAuth } from "store/entities/auth/useAuth";

const _required: Rule = {
	required: true,
	message: "This field is required.",
};

function LoginForm() {
	const { login } = useAuth();

	const attemptLogin = (values: any) => {
		const { symbolNumber = "", citizenshipNumber = "" } = values;

		login(symbolNumber, citizenshipNumber);
	};

	return (
		<div className="login__form">
			<h3>Examinee Log in</h3>
			<Form
				onFinish={attemptLogin}
				name="examnee__login"
				layout="vertical"
			>
				<Form.Item
					rules={[_required]}
					name="symbolNumber"
					label="Symbol Number"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					rules={[_required]}
					name="citizenshipNumber"
					label="Citizenship Number"
					required
				>
					<Input />
				</Form.Item>

				<Button htmlType="submit" block type="primary">
					Log in
				</Button>
			</Form>
		</div>
	);
}

export default LoginForm;
