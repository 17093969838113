import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useAuth } from "store/entities/auth/useAuth";
import HomeView from "views/HomeView/home.view";
import LoginView from "views/LoginView/login.view";

// views
import QuizView from "views/QuizView/quiz.view";

function AppRoutes() {
	const { isLogged } = useAuth();

	return (
		<div className="routes">
			<Switch>
				{isLogged ? (
					<>
						<Route path="/" exact component={QuizView} />
						<Redirect from="/**" to="/" />
					</>
				) : (
					<>
						<Route path="/" exact component={HomeView} />
						<Route path="/login" component={LoginView} />
					</>
				)}
			</Switch>
		</div>
	);
}

export default AppRoutes;
