import React from "react";

import "./home.styles.scss";

import logo from "assets/logo.png";
import { Link } from "react-router-dom";
import { Button } from "antd";

function HomeView() {
	return (
		<div className="homeView">
			<div className="content">
				<img src={logo} alt="logo" />
				<div className="information">
					<h2>Information for Candidates.</h2>

					<ol>
						<li>
							Shift details for each candidate is as follows:
							<ul>
								<li>
									Shift 1: Login Time: 7:30 AM (Zoom) MBA 1001
									to MBA 1120
								</li>
								<li>
									Shift 2: Login Time: 11:00 AM (Zoom) MBA
									1121 to MBA 1240
								</li>
								<li>
									Shift 3: Login Time: 3:00 PM (Zoom) MBA 1241
									to MBA 1356
								</li>
							</ul>
						</li>
						<li>
							Shift and group information has been sent to
							candidates via mail.
						</li>
						<li>
							Your username and password combinations have been
							sent to your email.
							<br />
							<b>
								Do not share it with anyone and use that to
								login to the exam portal.
							</b>
						</li>
						<li>
							Contact SOMTU management, if you have any issues
							while logging into the system. - Remember: You will
							be given access to the system only after you are in
							your round. - Accessing the system in rounds other
							than your provided time slot can lead to strict
							disciplinary results.
							<ul>
								<li>
									Contact For Emergency: 9851104419,
									9851104493, 9851124472
								</li>
								<li>
									Please do not call. No call will be
									entertained.
								</li>
								<li>
									Send SMS in the format: `Symbol Number`
									`Reason` `Details`
								</li>
								<li>
									You will receive a reply to your text which
									means that your issue has been noted.
								</li>
								<li>
									You will be contacted by SOMTU management in
									case of further queries.
								</li>
							</ul>
						</li>
						<li>
							Rules to be followed:{" "}
							<a href="https://www.youtube.com/watch?v=A0BVjmjznlo">
								https://www.youtube.com/watch?v=A0BVjmjznlo
							</a>
						</li>
						<li>
							For video reference, please check out this youtube
							video: <br />
							<a href="https://www.youtube.com/watch?v=zGX0kUOJQO4">
								https://www.youtube.com/watch?v=zGX0kUOJQO4
							</a>
						</li>
					</ol>
				</div>
				<Link to="/login">
					<Button type="primary">Login</Button>
				</Link>
			</div>
		</div>
	);
}

export default HomeView;
