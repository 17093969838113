import { Image, Input } from "antd";
import React, { useRef } from "react";
import { FC } from "react";
import { ILongQuestion } from "store/entities/quiz/quiz.model";
import {
	FilePdfOutlined,
	FileImageOutlined,
	FileOutlined,
} from "@ant-design/icons";
// import HTMLParser from 'html-react-parser'

interface LongQuestionWidgetProps {
	id: number;
	files: File[] | null;
	setFiles: (files: File[]) => void;
	question: ILongQuestion;
}

const LongQuestionWidget: FC<LongQuestionWidgetProps> = ({
	id,
	files: pFiles,
	setFiles: pSetFiles,
	question,
	...otherProps
}) => {
	const fileInputRef = useRef<any>(null);

	return (
		<div className="question" {...otherProps}>
			<div style={{ marginTop: 30 }} className="content">
				<h2 style={{ marginBottom: 15 }} className="text">
					{question?.title || <code>Loading...</code>}
				</h2>
				{question?.subtext && <p>{question.subtext}</p>}
				{question?.image && question?.image.length ? (
					<div className="images">
						<div className="image">
							<Image src={question.image} />
						</div>
					</div>
				) : null}
			</div>
			<div
				className="uploader"
				onClick={() => {
					fileInputRef?.current?.input?.click();
				}}
			>
				<Input
					ref={fileInputRef}
					type="file"
					multiple
					onChange={(ev) => {
						const _files = ev.target.files;
						if (_files) {
							let f: File[] = [];
							for (let i = 0; i < _files.length; i++) {
								f.push(_files[i]);
							}
							console.log({ f });
							pSetFiles(f);
						}
					}}
				/>
				<div className="files">
					{pFiles &&
						pFiles.map((item, i) => (
							<div className="file" key={i}>
								{item.type === "application/pdf" ? (
									<FilePdfOutlined />
								) : item.type === "image/png" ||
								  item.type === "image/jpg" ? (
									<FileImageOutlined />
								) : (
									<FileOutlined />
								)}
							</div>
						))}
					{!pFiles && (
						<div className="hint">
							<p>Click here to select files to upload</p>
							<small>
								Supported file formats: .pdf, .docs, .jpg, .png
							</small>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LongQuestionWidget;
