import rAxios from "utils/rAxios";
import {
	IHeartbeatResponse,
	ILongQuestionGetResponse,
	IMCQQuestionGetResponse,
} from "./quiz.model";

export const onMCQQuestionGet = (): Promise<IMCQQuestionGetResponse> =>
	rAxios.get("/user/questions");

export const onLongQuestionsGet = (): Promise<ILongQuestionGetResponse> =>
	rAxios.get("/user/questions");

export const onMCQAnser = (
	questionId: string,
	answer: number
): Promise<{ message: string }> =>
	rAxios.post(`/user/mcq/${questionId}`, {
		option: answer,
	});

export const onMCQSkip = (questionId: string): Promise<{ message: string }> =>
	rAxios.post(`user/mcq/${questionId}?type=skip`);

export const onLongQuestionAnswer = (questionId: string, files: File[]) => {
	const fd = new FormData();

	for (let file of files) fd.append("files", file);

	return rAxios.post(`/user/lngq/${questionId}`, fd, {
		timeout: 10 * 60 * 1000, // 10 minutes
	});
};

export const onBeat = (): Promise<IHeartbeatResponse> =>
	rAxios.get("/user/heartbeat");
