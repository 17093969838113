import jwtDecode from "jwt-decode";
import rAxios from "utils/rAxios";
import { IExamineeLoginResponse, IUser } from "./auth.model";

export const onExamineeLogin = (
	symbolNumber: string,
	citizenshipNumber: string
): Promise<IExamineeLoginResponse> =>
	rAxios.post("/user/login", {
		symbolNumber,
		citizenshipNumber,
	});

export const decodeToken = (token: string): IUser => jwtDecode(token);
