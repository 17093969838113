import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	ExamSessionType,
	IExamType,
	ILongQuestion,
	IMCQQuestion,
} from "./quiz.model";

interface IQuizRedux {
	index: number;
	answering: boolean;
	mcqStatus: ExamSessionType;
	type: IExamType;
	mcqQuestion: IMCQQuestion | null;
	longQuestionStatus: ExamSessionType;
	longQuestions: ILongQuestion[];
	status: "idle" | "loading" | "loaded" | "error";
	error?: string;
}

const initState: IQuizRedux = {
	answering: false,
	mcqStatus: "waiting",
	mcqQuestion: null,
	index: 0,
	longQuestionStatus: "waiting",
	longQuestions: [],
	type: "MCQ",
	status: "idle",
	error: "",
};

// reducers
const newType: CaseReducer<IQuizRedux, PayloadAction<IExamType>> = (
	state,
	action
) => {
	state.type = action.payload;
	return state;
};
const newMCQQuestion: CaseReducer<
	IQuizRedux,
	PayloadAction<{
		question: IMCQQuestion | null;
		index: number;
	}>
> = (state, action) => {
	state.mcqQuestion = action.payload.question;
	state.index = action.payload.index;
	state.status = "loaded";
	state.error = "";
	return state;
};
const newLongQuestions: CaseReducer<
	IQuizRedux,
	PayloadAction<ILongQuestion[]>
> = (state, action) => {
	state.longQuestions = action.payload;
	state.index = 0;
	state.status = "loaded";
	state.error = "";
	return state;
};
const answering: CaseReducer<IQuizRedux> = (state) => {
	state.answering = true;
	state.error = "";
	return state;
};
const answeringSuccess: CaseReducer<IQuizRedux> = (state) => {
	state.answering = false;
	state.error = "";
	return state;
};
const loading: CaseReducer<IQuizRedux> = (state) => {
	state.status = "loading";
	state.error = "";
	return state;
};

const failed: CaseReducer<IQuizRedux, PayloadAction<string>> = (
	state,
	action
) => {
	state.status = "error";
	state.error = action.payload;
	return state;
};

const updateMCQExamState: CaseReducer<
	IQuizRedux,
	PayloadAction<ExamSessionType>
> = (state, action) => {
	state.mcqStatus = action.payload;
	return state;
};
const updateLongQuestionExamState: CaseReducer<
	IQuizRedux,
	PayloadAction<ExamSessionType>
> = (state, action) => {
	state.longQuestionStatus = action.payload;
	return state;
};

// reducers ends

export const quizSlice = createSlice({
	name: "quiz",
	initialState: initState,
	reducers: {
		newType,
		newMCQQuestion,
		newLongQuestions,
		answering,
		answeringSuccess,
		updateMCQExamState,
		updateLongQuestionExamState,
		loading,
		failed,
	},
});
export const quizActions = quizSlice.actions;
