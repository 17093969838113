import React from "react";
import QuizAnswerChoices from "../widgets/Question/QuizAnswerChoices";
import QuizQuestionWidget from "../widgets/Question/QuizQuestion";

interface InSessionMCQViewProps {
	choosen: number;
	onAnswerChoose: (index: number) => void;
}

function InSessionMCQView({ choosen, onAnswerChoose }: InSessionMCQViewProps) {
	return (
		<>
			<QuizQuestionWidget />
			<QuizAnswerChoices {...{ choosen, onAnswerChoose }} />
		</>
	);
}

export default InSessionMCQView;
