import React from "react";
import { useQuiz } from "store/entities/quiz/useQuiz";
import AnswerOption from "../Answer/AnswerOption";

interface QuizAnswerChoicesProps {
	choosen: number;
	onAnswerChoose: (index: number) => void;
}

function QuizAnswerChoices({
	onAnswerChoose,
	choosen,
}: QuizAnswerChoicesProps) {
	const { mcqQuestion: question, type, status } = useQuiz();

	return status === "loaded" ? (
		type === "MCQ" ? (
			question && (
				<div className="answers">
					<ul>
						{[
							question.option1,
							question.option2,
							question.option3,
							question.option4,
						].map((item, i) => (
							<AnswerOption
								choosen={choosen}
								id={i + 1}
								option={item}
								key={`ansswer-choices-${i}`}
								onClick={() => {
									onAnswerChoose(i + 1);
								}}
							/>
						))}
					</ul>
				</div>
			)
		) : null
	) : (
		<p>Loading...</p>
	);
}

export default QuizAnswerChoices;
