import { Button } from "antd";
import React from "react";
import { useAuth } from "store/entities/auth/useAuth";

function EndSessionMessageLongView() {
	const { logout } = useAuth();

	return (
		<div className="session__waiting">
			<div className="message">
				<div className="detail">
					<h3>Your case study session has ended.</h3>
					<h2>
						Please, wait for the administration to release scores.
					</h2>
					<Button type="link" onClick={logout}>
						Log out
					</Button>
				</div>
			</div>
		</div>
	);
}

export default EndSessionMessageLongView;
