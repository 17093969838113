import React, { FC } from "react";
import { ImRadioChecked2, ImRadioUnchecked } from "react-icons/im";
import { IMCQOption } from "store/entities/quiz/quiz.model";

interface AnswerOptionProps {
	option: IMCQOption;
	id: number;
	choosen: number;
	onClick: () => void;
}

const AnswerOption: FC<AnswerOptionProps> = ({
	option,
	id,
	choosen,
	onClick,
	...otherProps
}) => {
	return (
		<li {...otherProps} onClick={onClick}>
			<div className="answerOption">
				<div className="radio">
					{id === choosen ? (
						<ImRadioChecked2 size={18} />
					) : (
						<ImRadioUnchecked size={18} />
					)}
				</div>
				<div className="content">
					<div className="text">{option.text}</div>
					{option.image ? (
						<div className="images">
							<img src={option.image} alt="anwer visual asset" />
						</div>
					) : null}
				</div>
			</div>
		</li>
	);
};

export default AnswerOption;
