import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { quizActions } from "./quiz.redux";
import {
	onBeat,
	onLongQuestionAnswer,
	onMCQAnser,
	onMCQSkip,
	onMCQQuestionGet,
	onLongQuestionsGet,
} from "./quiz.service";

export const useQuiz = () => {
	const quizStates = useAppSelector((state) => state.quiz);

	const dispatch = useAppDispatch();

	const checkHeartbeat = async () => {
		try {
			const resp = await onBeat();

			dispatch(quizActions.updateMCQExamState(resp.mcq));
			dispatch(quizActions.updateLongQuestionExamState(resp.lngq));
		} catch (ex) {
			console.log("CRITICAL: Heartbeat check failed.");
		}
	};

	const getMCQ = async () => {
		try {
			dispatch(quizActions.loading());

			const resp = await onMCQQuestionGet();

			dispatch(quizActions.newType(resp.type));
			dispatch(
				quizActions.newMCQQuestion({
					question: resp.question,
					index: resp.index,
				})
			);
		} catch (ex) {
			dispatch(
				quizActions.failed(
					ex.message || "Could not fetch question at the moment."
				)
			);
			if (quizStates.index !== 30) dispatch(quizActions.newType("LONG"));
			else
				message.error(
					ex.response?.data?.message ||
						ex.message ||
						"There was an error fetching question. Retrying..."
				);
			// setTimeout(getMCQ, 1000 * 5);
		}
	};

	const getLongQuestion = async () => {
		try {
			dispatch(quizActions.loading());

			const resp = await onLongQuestionsGet();

			dispatch(quizActions.newType(resp.type));
			dispatch(quizActions.newLongQuestions(resp.questions));
		} catch (ex) {
			dispatch(
				quizActions.failed(
					ex.message || "Could not fetch question at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					ex.message ||
					"There was an error fetching question. Retrying..."
			);
			setTimeout(getLongQuestion, 1000 * 5);
		}
	};

	const answerMCQ = async (answerNumber: number | null) => {
		try {
			dispatch(quizActions.answering());
			if (answerNumber)
				await onMCQAnser(
					quizStates.mcqQuestion?._id || "",
					answerNumber
				);
			else await onMCQSkip(quizStates.mcqQuestion?._id || "");
			dispatch(quizActions.answeringSuccess());
			getMCQ();
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					ex.message ||
					"There was an error submitting your answer. Try again"
			);
			throw ex;
		}
	};

	const answerLongQuestion = async (questionId: string, files: File[]) => {
		try {
			dispatch(quizActions.answering());
			await onLongQuestionAnswer(questionId, files);
			dispatch(quizActions.answeringSuccess());
			dispatch(quizActions.updateLongQuestionExamState("finished"));

			message.success("Case study answer has been submitted.");
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					ex.message ||
					"There was an error submitting your answer. Try again"
			);
			throw ex;
		}
	};

	return {
		...quizStates,
		getMCQ,
		getLongQuestion,
		answerMCQ,
		answerLongQuestion,
		checkHeartbeat,
	};
};
