import { message } from "antd";
import { onTimerGet } from "./timer.service";

export const useTimer = () => {
	const getTimer = async () => {
		try {
			const resp = await onTimerGet();

			return resp;
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					ex.message ||
					"Failed to fetch time data."
			);
			throw ex;
		}
	};

	return { getTimer };
};
