import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { authActions } from "./auth.redux";
import { decodeToken, onExamineeLogin } from "./auth.service";

export const useAuth = () => {
	const authStates = useAppSelector((state) => state.auth);

	const dispatch = useAppDispatch();

	const login = async (symbolNumber: string, citizenshipNumber: string) => {
		try {
			dispatch(authActions.loading());
			const resp = await onExamineeLogin(symbolNumber, citizenshipNumber);

			if (resp.user.role !== "USER") throw new Error("Access denied.");

			dispatch(authActions.login(resp));
			localStorage.setItem("token", resp.token);

			message.success("Logged in successfully.");
		} catch (ex) {
			dispatch(authActions.failed(ex.message || "Something went wrong."));
			message.error(
				ex.response?.data?.message ||
					ex.message ||
					"Log-in failed. Try again."
			);
		}
	};

	const logout = () => {
		dispatch(authActions.logout());
		localStorage.removeItem("token");
		message.success("You have been logged out.");
	};

	const quickLogin = async (token: string) => {
		try {
			const user = decodeToken(token);

			if (user.role !== "USER") throw new Error("Access denied.");

			dispatch(
				authActions.login({
					token,
					user: user,
				})
			);
		} catch (ex) {
			message.error(ex.message || "Could not quick-login. Log in again.");
			localStorage.removeItem("token");
			logout();
		}
	};

	return { ...authStates, login, quickLogin, logout };
};
