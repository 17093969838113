import React from 'react'
import { useQuiz } from 'store/entities/quiz/useQuiz'
import HTMLParser from 'html-react-parser'

const _totalQuestions = 30

function QuizQuestionWidget() {
	const { mcqQuestion: question, index } = useQuiz()

	return (
		question && (
			<div className="question">
				<header>
					<h3>
						Problem: {index + 1} / {_totalQuestions}
					</h3>
				</header>
				<div className="content">
					<h2 className="text">{HTMLParser(question.title)}</h2>
					{question.image && question?.image.length ? (
						<div className="images">
							<img
								src={question.image}
								alt="question descriptive visual content"
							/>
						</div>
					) : null}
				</div>
			</div>
		)
	)
}

export default QuizQuestionWidget
