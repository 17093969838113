import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

import { ITimer } from "store/entities/timer/timer.model";
import { useTimer } from "store/entities/timer/useTimer";
import { differenceInMilliseconds } from "date-fns";

const _threshold = 2; // 2 minutes
interface LiveWatchProps {
	onExpiration?: () => void;
}

function LiveWatchToLongQuestion({ onExpiration }: LiveWatchProps) {
	// const [timeDiff, setTimeDiff] = useState<number>(0);
	const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
	const [requesting, setRequesting] = useState<boolean>(false);

	const timer = useTimer();

	useEffect(() => {
		setRequesting(true);
		timer
			.getTimer()
			.then((timers) => {
				const _timers: ITimer = {
					mcqEndTime: new Date(timers.mcqEndTime),
					mcqStartTime: new Date(timers.mcqStartTime),
					lngqEndTime: new Date(timers.lngqEndTime),
					lngqStartTime: new Date(timers.lngqStartTime),
					currentTime: new Date(timers.currentTime),
				};

				let endTime = _timers.lngqStartTime;

				let difInMilSeconds = Math.abs(
					differenceInMilliseconds(
						new Date(_timers.currentTime),
						new Date(endTime)
					)
				);

				const shifted = difInMilSeconds + Date.now();

				setSecondsRemaining(shifted);
			})
			.catch(() => {})
			.finally(() => {
				setRequesting(false);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="watch">
			<small>Time till case study sesssion starts</small>
			{!requesting ? (
				<Countdown
					date={secondsRemaining}
					onComplete={() => {
						onExpiration && onExpiration();
					}}
					renderer={({ hours, minutes, seconds }) => {
						return (
							<h2
								className={`clock ${
									hours === 0 && minutes <= _threshold
										? " red"
										: ""
								}`}
							>
								{minutes.toString().padStart(2, "0")}:
								{seconds.toString().padStart(2, "0")}
							</h2>
						);
					}}
				/>
			) : null}
		</div>
	);
}

export default LiveWatchToLongQuestion;
