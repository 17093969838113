import React, { useState } from "react";
import { Layout, Skeleton } from "antd";
import { useQuiz } from "store/entities/quiz/useQuiz";

import LiveWatch from "../widgets/Time/LiveWatch";
import AnswerSubmit from "../widgets/Answer/AnswerSubmit";
import WaitingMCQView from "./Waiting.mcq";
import InSessionMCQView from "./InSession.mcq";
import EndSessionMessageMCQView from "./EndSessionMessage.mcq";
import { useEffect } from "react";
import { useLogger } from "store/entities/logs/useLogger";
import { useMediaQuery } from "react-responsive";

function MCQView() {
	const { mcqStatus, answerMCQ, getMCQ, status } = useQuiz();
	const logger = useLogger();

	const isMobileDevice = useMediaQuery({ maxWidth: 480 });

	useEffect(() => {
		if (mcqStatus === "insession") {
			getMCQ();

			window.addEventListener("resize", logger.logResize);
			window.addEventListener("blur", logger.logBlur);
			window.addEventListener("mouseleave", logger.logMouseLeave);
		} else {
			window.removeEventListener("resize", logger.logResize, true);
			window.removeEventListener("blur", logger.logBlur, true);
			window.removeEventListener(
				"mouseleave",
				logger.logMouseLeave,
				true
			);
		}

		return () => {
			window.removeEventListener("resize", logger.logResize, true);
			window.removeEventListener("blur", logger.logBlur, true);
			window.removeEventListener(
				"mouseleave",
				logger.logMouseLeave,
				true
			);
		};

		// eslint-disable-next-line
	}, [mcqStatus]);

	const [answer, setAnswer] = useState<number>(0);

	const clearAnswer = () => setAnswer(0);

	const _answerMCQ = () => {
		answerMCQ(answer)
			.then(clearAnswer)
			.catch(() => {});
	};

	const _skipMCQ = () => {
		answerMCQ(null)
			.then(clearAnswer)
			.catch(() => {});
	};

	return (
		<Layout style={{ position: "relative" }}>
			<Layout.Content className="quizPost">
				{status === "loading" ? (
					<Skeleton />
				) : mcqStatus === "waiting" ? (
					<WaitingMCQView />
				) : mcqStatus === "insession" ? (
					<>
						{isMobileDevice ? (
							<LiveWatch
								onExpiration={answer ? _answerMCQ : _skipMCQ}
							/>
						) : null}
						<InSessionMCQView
							choosen={answer}
							onAnswerChoose={setAnswer}
						/>
						{isMobileDevice ? (
							<AnswerSubmit
								disabled={!answer}
								onSubmit={_answerMCQ}
								onSkip={_skipMCQ}
							/>
						) : null}
					</>
				) : (
					<EndSessionMessageMCQView />
				)}
			</Layout.Content>
			{mcqStatus === "insession" ? (
				<Layout.Sider hidden={isMobileDevice} className="quizSideBar">
					<div className="content">
						<LiveWatch
							onExpiration={answer ? _answerMCQ : _skipMCQ}
						/>
						<AnswerSubmit
							disabled={!answer}
							onSubmit={_answerMCQ}
							onSkip={_skipMCQ}
						/>
					</div>
				</Layout.Sider>
			) : null}
		</Layout>
	);
}

export default MCQView;
