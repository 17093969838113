import React from "react";
import { Button, Popconfirm } from "antd";
import { useQuiz } from "store/entities/quiz/useQuiz";

interface AnswerSubmitProps {
	disabled: boolean;
	longQuestion?: boolean;
	onSubmit: () => void;
	onSkip?: () => void;
}

function AnswerSubmit({
	longQuestion,
	disabled,
	onSubmit,
	onSkip,
}: AnswerSubmitProps) {
	const { answering } = useQuiz();

	return (
		<div className="submitAnswer">
			<Button
				disabled={answering || disabled}
				loading={answering}
				onClick={onSubmit}
				type="primary"
				block
			>
				{longQuestion ? "Submit" : "Submit, Next Question"}
			</Button>
			{onSkip && (
				<Popconfirm
					onConfirm={() => {
						onSkip();
					}}
					title="Are you sure?"
					placement="leftBottom"
					okText="Yes, skip"
				>
					<Button
						disabled={answering}
						loading={answering}
						block
						style={{ marginTop: 20 }}
						type="text"
					>
						Skip this question
					</Button>
				</Popconfirm>
			)}
		</div>
	);
}

export default AnswerSubmit;
