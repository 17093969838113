import React, { useState } from "react";
import { Layout } from "antd";
import { useQuiz } from "store/entities/quiz/useQuiz";

import WaitingLongView from "./Waiting.long";
import EndSessionMessageLongView from "./EndSessionMessage.long";
import LongQuestionWidget from "./LongQuestionWidget";
import { useEffect } from "react";
import { useLogger } from "store/entities/logs/useLogger";
import AnswerSubmit from "../widgets/Answer/AnswerSubmit";
import LiveWatchLongQuestion from "./LiveWatchLongQuestion";
import { useMediaQuery } from "react-responsive";

function LongQuestionView() {
	const {
		longQuestions,
		getLongQuestion,
		longQuestionStatus,
		answerLongQuestion,
		status,
	} = useQuiz();

	const logger = useLogger();
	const isMobileDevice = useMediaQuery({ maxWidth: 480 });

	useEffect(() => {
		if (longQuestionStatus === "insession") {
			window.addEventListener("resize", logger.logResize);
			window.addEventListener("blur", logger.logBlur);
			window.addEventListener("mouseleave", logger.logMouseLeave);
		} else {
			window.removeEventListener("resize", logger.logResize, true);
			window.removeEventListener("blur", logger.logBlur, true);
			window.removeEventListener(
				"mouseleave",
				logger.logMouseLeave,
				true
			);
		}

		return () => {
			window.removeEventListener("resize", logger.logResize, true);
			window.removeEventListener("blur", logger.logBlur, true);
			window.removeEventListener(
				"mouseleave",
				logger.logMouseLeave,
				true
			);
		};

		// eslint-disable-next-line
	}, [longQuestionStatus]);

	useEffect(() => {
		if (longQuestionStatus === "insession") getLongQuestion();
		// eslint-disable-next-line
	}, [longQuestionStatus]);

	const [files1, setFiles1] = useState<File[] | null>(null);

	const questionSet = longQuestions[0];
	const _answerLnQ = () => {
		if (files1) {
			answerLongQuestion(questionSet._id, files1)
				.then(() => {})
				.catch(() => {});
		}
	};
	return (
		<Layout>
			<Layout.Content className="quizPost">
				{longQuestionStatus === "waiting" ? (
					<WaitingLongView />
				) : longQuestionStatus === "insession" ? (
					status === "loaded" ? (
						<>
							{isMobileDevice ? <LiveWatchLongQuestion /> : null}
							<LongQuestionWidget
								id={1}
								question={questionSet}
								files={files1}
								setFiles={setFiles1}
								key={`long-question-${1}`}
							/>
							{isMobileDevice ? (
								<AnswerSubmit
									disabled={files1?.length === 0}
									longQuestion
									onSubmit={_answerLnQ}
								/>
							) : null}
						</>
					) : null
				) : (
					<EndSessionMessageLongView />
				)}
			</Layout.Content>
			{longQuestionStatus === "insession" ? (
				<Layout.Sider hidden={isMobileDevice} className="quizSideBar">
					<div className="content">
						<LiveWatchLongQuestion />
						<AnswerSubmit
							disabled={files1?.length === 0}
							longQuestion
							onSubmit={_answerLnQ}
						/>
					</div>
				</Layout.Sider>
			) : null}
		</Layout>
	);
}

export default LongQuestionView;
