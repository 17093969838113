import { LOG_TYPE } from "./logs.model";
import { sendLog } from "./logs.service";

const handleException = async (fn: Promise<any>) => {
	try {
		await fn;
	} catch (ex) {
		console.log("CRITICAL: log send failed.");
	}
};

export const useLogger = () => {
	const logBlur = () => handleException(sendLog(LOG_TYPE.DEFOCUS));
	const logLogout = () => handleException(sendLog(LOG_TYPE.LOGOUT));
	const logResize = () => handleException(sendLog(LOG_TYPE.RESIZE));
	const logMouseLeave = () => handleException(sendLog(LOG_TYPE.MOUSELEAVE));

	return { logBlur, logLogout, logMouseLeave, logResize };
};
