import React from "react";
import { useQuiz } from "store/entities/quiz/useQuiz";
import LiveWatchToLongQuestion from "./LiveWatchToLongQuestion";

function WaitingLongView() {
	const { checkHeartbeat } = useQuiz();

	const onExpire = () => {
		checkHeartbeat();
	};

	return (
		<div className="session__waiting">
			<div className="message">
				<div className="detail">
					<LiveWatchToLongQuestion onExpiration={onExpire} />
					<h3>
						Your case study session has not started yet. Please
						wait.
					</h3>
					<small>
						You will be redirected to your session as soon as it
						start.
					</small>
				</div>
			</div>
		</div>
	);
}

export default WaitingLongView;
