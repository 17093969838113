export enum LOG_TYPE {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	SUBMIT = "SUBMIT",
	SKIP = "SKIP",
	DEFOCUS = "DEFOCUS",
	RESIZE = "RESIZE",
	MOUSELEAVE = "MOUSELEAVE",
}

export interface ILogSendModel {
	log_type: LOG_TYPE;
	details: string;
}
