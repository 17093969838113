import React from "react";
import { Layout } from "antd";
import { useQuiz } from "store/entities/quiz/useQuiz";
import { useEffect } from "react";

import MCQView from "./MCQ/MCQView";
import AppHeader from "components/AppHeader";
import LongQuestionView from "./LongQuestion/LongQuestionView";

import "./quiz.styles.scss";

function QuizView() {
	const { type, mcqStatus, longQuestionStatus, checkHeartbeat } = useQuiz();

	useEffect(() => {
		const beatInterval = setInterval(() => {
			if (longQuestionStatus !== "finished") checkHeartbeat();
		}, 1000 * 15);

		return () => {
			clearInterval(beatInterval);
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		checkHeartbeat();
		// eslint-disable-next-line
	}, []);

	return (
		<Layout>
			<AppHeader />
			<div className="quizView">
				{longQuestionStatus !== "finished" &&
				mcqStatus !== "finished" &&
				type === "MCQ" ? (
					<MCQView />
				) : (
					<LongQuestionView />
				)}
			</div>
		</Layout>
	);
}

export default QuizView;
